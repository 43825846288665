import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
import { useState } from "react";

function ComingSoon(props) {

    const [IsMobile, setIM] = useState(isMobile);

    return (
        <>

                {!IsMobile && 
                    <div className="modal-overlay p-5 mobilepadding">
                        <div className="container-fluid h-100">
                            <div className="row  h-100">
                                <div className="modal-popup-background">
                                    <div className="row  h-100">
                                        <div
                                            className="col col-12 col-xs-12  my-auto">

                                                <h2 className="float-left w-100 text-center text-dark2 nametxt mb-0">Feature Coming Soon</h2>
                                                
                                                <h4 className="float-left w-100 text-center text-dark2 registrationemailtxt" style={{marginTop:"2rem"}}>This feature is coming soon, stay tuned!</h4>
                                                
                                                <div
                                                    className="col-12 float-left w-100 text-center mt-4 wizsubbtn centerbox registrationcreatebtn">
                                                        <button type="button" onClick={props.onClose}
                                                                className="default-btn next-step">
                                                            <img src="/images/button-design/Button-V4.png" alt="button"
                                                                className="img-fluid"/>
                                                            <span
                                                                className="col-12 float-left w-100 subnxttxt">Close</span>
                                                        </button>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                 {IsMobile && 
                    <div className="modal-overlay mobilepadding">
                        <div className="container-fluid h-100">
                            <div className="row  h-100">
                                <div className="modal-popup-background">
                                    <div className="row  h-100">
                                        <div
                                            className="col col-12 col-xs-12  my-auto">

                                                <h2 className="float-left w-100 text-center text-dark2 mb-0 guardian-text" style={{fontSize:"30px"}}>Feature Coming Soon</h2>
                                                
                                                <h4 className="float-left w-100 text-center text-dark2 registrationemailtxt" style={{marginTop:"2rem", fontSize:"24px", padding:"0 15%"}}>This feature is coming soon, stay tuned!</h4>
                                                
                                                <div
                                                    className="col-12 float-left w-100 text-center mt-4 wizsubbtn centerbox registrationcreatebtn">
                                                        <button type="button" onClick={props.onClose}
                                                                className="default-btn next-step">
                                                            <img src="/images/button-design/Button-V4.png" alt="button"
                                                                className="img-fluid"/>
                                                            <span
                                                                className="col-12 float-left w-100 subnxttxt">Close</span>
                                                        </button>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(ComingSoon);