import axios from 'axios';
import {store} from '../Store/store';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    if (state.auth.auth.token) {
        config.headers.authorization = state.auth.auth.token;
    }

    return config;
});

export default axiosInstance;