import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    REGISTER_CONFIRMED_ACTION,
    REGISTER_FAILED_ACTION,
    LOGOUT_ACTION,
    SAVE_PARTNER_CODE_ACTION,
    GET_SUBSCRIPTION_DETAILS_ACTION,
    RESET_ERROR_MESSAGE
} from "../actions/AuthActions";

const initialState = {
    auth: {
        ownerID: '',
        nickname: '',
        profilePhoto: '',
        isVerified: '',
        token: '',
        fullName: '',
        email: '',
    },
    purchaseStatus:{
        status: "inactive"
    },
    paymentMethod:{

    },
    partnerCode: localStorage.getItem('partnerCode'),
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === REGISTER_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Registration Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }
    if(action.type ===GET_SUBSCRIPTION_DETAILS_ACTION){
        return {
            ...state,
            purchaseStatus: action.payload.purchaseStatus,
            paymentMethod: action.payload.paymentMethod,
            errorMessage: '',
            successMessage: 'Subscription Status Successful',
            showLoading: false,
        };
    }
    if (action.type === SAVE_PARTNER_CODE_ACTION) {
        return {
            ...state,
            partnerCode: action.payload
        };
    }
    if (action.type === REGISTER_FAILED_ACTION || action.type === LOGIN_FAILED_ACTION) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            showLoading: action.payload,
        };
    }
    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
            errorMessage: '',
            successMessage: '',
        };
    }
    if(action.type === RESET_ERROR_MESSAGE){
        return {
            ...state,
            errorMessage: '',
        }
    }
    return state;
}