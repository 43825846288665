import axiosInstance from "./AxiosInstance";
import {loginConfirmedAction, logoutAction, subscriptionConfirmedAction} from "../Store/actions/AuthActions";

export function register(postData) {
    return axiosInstance.post(`admin/owner/registration`, postData);
}

export function socialRegister(postData) {
    return axiosInstance.post(`admin/owner/social-registration`, postData);
}

export function logPartnerView(partnerCode) {
    return axiosInstance.get(`owner/logpartner/`+partnerCode,{ headers: {
        'Authorization':`Basic ${process.env.REACT_APP_SERVER_AUTHORIZATION}`
    }});
}

export function login(email, password) {
    const postData = {
        email: email,
        password: password
    };
    return axiosInstance.post(`admin/owner/login`, postData);
}

export function logout() {
    return axiosInstance.post(`admin/owner/logout`, {});
}

export function forgotPassword(data) {
    return axiosInstance.post(`admin/owner/forgot-password`, data);
}

export function resetPassword(data) {
    return axiosInstance.post(`admin/owner/reset-password`, data);
}

export function getSpeciesList() {
    return axiosInstance.post(`admin/species-list`, {});
}

export function getBreedList(data) {
    return axiosInstance.post(`admin/breed-list`, data);
}

export function getSubscriptionStatus() {
    return axiosInstance.get(`admin/owner/get-subscription-status`, {});
}


export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem('paraOwner', JSON.stringify(tokenDetails));
}

export function saveSubscriptionInLocalStorage(subDetails) {
    localStorage.setItem('ownerSubscription', JSON.stringify(subDetails));
}

export function savePartnersCodeInLocalStorage(partnerCode) {
    localStorage.setItem('partnerCode', partnerCode);
}

export function getPartnersCodeInLocalStorage() {
    localStorage.getItem('partnerCode');
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('paraOwner');
    const subscriptionString = localStorage.getItem('ownerSubscription');
    let tokenDetails = {};
    if (!tokenDetailsString) {
        dispatch(logoutAction(history));
        return;
    }
    let subscriptionDetails = {}

    if(!subscriptionString){
        getSubscriptionStatus().then(response=>{
            saveSubscriptionInLocalStorage(response.data.data);
            dispatch(subscriptionConfirmedAction(response.data.data));
            subscriptionDetails = response.data.data;
        });
    } else {
        subscriptionDetails = JSON.parse(subscriptionString);
    }

    
    tokenDetails = JSON.parse(tokenDetailsString);

    dispatch(loginConfirmedAction(tokenDetails));
    dispatch(subscriptionConfirmedAction(subscriptionDetails));

}

export function checkSubscription() {
    return axiosInstance.get(`admin/owner/get-subscription-status`);
}