import React, {useState, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import {
    isMobile
 } from "react-device-detect";
import ComingSoon from "../modals/coming-soon";
import {getPetDetail} from "../../services/PetService";
import Styles from './Nav.module.css'

function Nav(props) {
    const [dateState, setDateState] = useState(new Date());
    const [curHour, setCurHour] = useState(new Date().getHours());
    const audioRefHomePage = useRef();
    const audioRefDiscovery = useRef();
    const audioRefPetCreation = useRef();
    const audioRefForestCottage = useRef();
    const audioRefEvent = useRef();
    const [isPlay, setIsPlay] = useState(false);
    const [comingSoon, setCS] = useState(false);
    const [isMobileState, setIM] = useState(isMobile);
    const pathname = window.location.pathname;
    const [showNavbar, setShowNavbar] = useState(false);

    let isNew = true;

    let curAudioRef = null;
    const [curAudioState, setCAS] = useState(null);
    let curAudioName = "A New Beginning";
    const [curAudioStateName, setCASN] = useState("A New Beginning");

    const musicLibrary = [
        {path:"/", name:"A New Beginning", audioRef: audioRefHomePage, url:""},
        {path:"/discover", name:"Hall of Heroes", audioRef: audioRefDiscovery, url:""},
        {path:"/pets/ascend", name:"Beyond the Rainbow Bridge", audioRef: audioRefPetCreation, url:""},
        {path:"/pets", name:"Hall of Heroes", audioRef: audioRefDiscovery, url:""},
        {path:"/pet/view/premium", name:"Beyond the Rainbow Bridge", audioRef: audioRefPetCreation, url:""},
        {path:"/pet/view/normal", name:"Forest Sprites", audioRef: audioRefForestCottage, url:""},
        {path:"/event", name:"Bonfire Whispers", audioRef: audioRefEvent, url:""}
    ]


    useEffect(() => {
        //get current path name and set music
        for(let i=0; i<musicLibrary.length; i++){
            musicLibrary[i].audioRef.current.addEventListener('ended', function(){
                this.currentTime = 0;
                this.play();
            }, false)
        }
        setInterval(() => {
            setDateState(new Date());
            setCurHour(new Date().getHours());
        }, 1000);

        window.addEventListener('click', function(event) {
            if(isNew && !isPlay){
                if(curAudioRef == null || curAudioRef.current == null){
                    return;
                }
                setIsPlay(true);
                isNew = false;
                try{
                    curAudioRef.current.play();
                } catch (e) {

                }
            }
         });
         setIM(isMobile);
         //const [isMobileState, setIM] = useState(isMobile);
        
    }, []);

    useEffect(async ()=>{
        //change audio switch via pathname to see what audio to play
        if(curAudioState != null){
            curAudioState.current.pause();
        }
        let loopedAudio = audioRefHomePage;
        let loopedAudioName = "A New Beginning";
        let pathName = window.location.pathname;
        if(window.location.pathname.includes('view')){
            if(window.location.pathname.includes('pets')){
                let petID = pathName.split("/")[2];
                //alert(petID);
                await getPetDetail({id: petID})
                .then(response => {
                    let data = response.data.data;
                    if(data.isSubscribed){
                        pathName = "/pet/view/premium";
                    } else {
                        pathName = "/pet/view/normal";
                    }
                })
                .catch(err => {
                    
                });
            }
        }

        for(let i=0; i<musicLibrary.length; i++){
            if(musicLibrary[i].path == pathName){
                //assign
                loopedAudioName = musicLibrary[i].name;
                loopedAudio = musicLibrary[i].audioRef;
                break;
            }
        }
        setIsPlay(true);
        setCAS(loopedAudio);
        setCASN(loopedAudioName);
        curAudioRef = loopedAudio;
        curAudioName = loopedAudioName;
        curAudioRef.current.currentTime = 0;
        try{
            curAudioRef.current.play();
        } catch (e) {
            
        }
    }, [window.location.pathname])

    let timeIcon = 'morning-icon.png';
    if (curHour >= 19 && curHour < 20) {
        timeIcon = 'sunset-icon.png';
    } else if (curHour >= 20 || curHour < 6) {
        timeIcon = 'night-icon.png';
    }

    function showComingSoon(){
        setCS(true);
    }

    function onCSClose(){
        setCS(false);
    }


    function onMusicPlay(e) {
        e.preventDefault();
        curAudioState.current.play();
        setIsPlay(true);
    }

    function onMusicPause(e) {
        e.preventDefault();
        curAudioState.current.pause();
        setIsPlay(false);
    }

    return (
        <>
            {comingSoon && 
                <ComingSoon onClose={onCSClose}/>
            }
            <nav className={Styles.navbar} style={{backgroundImage: 'url(/images/menubar-navbar-desktop/background-bar.png)'}}>
                <ul className={Styles.navbarCollapse}>
                    <li className="nav-item timing-area">
                        <Link className="nav-link text-white" to={{pathname: '/'}}>
                            <img src={`/images/menubar-navbar-desktop/${timeIcon}`} alt="morning"
                                    className={Styles.timeIcon}/>
                            <span className={Styles.srOnlyTop}>{dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                                <span className={Styles.srOnlyBtm}>{dateState.toLocaleDateString('en-GB', {
                                    weekday: 'long',
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                })}</span>
                        </span>
                        </Link>
                    </li>
                    <li className={Styles.navbarLogo} style={{backgroundImage: 'url(/images/menubar-navbar-desktop/main-button.png)'}}>
                        <Link  to={{pathname: '/'}}>
                            <img src="/images/menubar-navbar-desktop/logo.png" alt="logo"
                                    className="img-fluid logo-img"/>
                        </Link>
                    </li>
                    <li className={Styles.navbarItem} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                        {/* <Link className="nav-link text-white" to={{pathname: '/pets'}}> */}
                        <Link className={Styles.navbarLink} to={{pathname: '/profile'}}>
                            <img src="/images/menubar-navbar-desktop/pet-icon.png" alt="pet"
                                    className={Styles.navbarImage}/> My Pets
                        </Link>
                    </li>
                    <li className={Styles.navbarItem} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                        <Link className={Styles.navbarLink} to={{pathname: '/event'}}>
                            <img src="/images/menubar-navbar-desktop/profile-icon.png" alt="profile"
                                    className={Styles.navbarImage}/> Chat Room
                        </Link>
                    </li>
                    <li className={Styles.navbarItem} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                        <Link className={Styles.navbarLink} to={{pathname: '/discover'}}>
                            <img src="/images/menubar-navbar-desktop/discover-icon.png" alt="discover"
                                    className={Styles.navbarImage}/> Discover
                        </Link>
                    </li>
                    <li className={Styles.navbarItem} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                        <Link className={Styles.navbarLink} to={{pathname: '/shop'}}>
                        {/* <Link className={Styles.navbarLink}  onClick={showComingSoon}> */}
                            <img src="/images/menubar-navbar-desktop/shop-icon.png" alt="shop"
                                    className={Styles.navbarImage}/> Shop
                        </Link>
                    </li>
                    <li className={Styles.navbarItem} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                        <Link className={Styles.navbarLink} onClick={showComingSoon}>
                        {/* <Link className={Styles.navbarLink} to={{pathname: '/event'}}> */}
                            <img src="/images/menubar-navbar-desktop/karma-icon.png" alt="karma"
                                    className={Styles.navbarImage}/> Karma
                        </Link>
                        {/* <Link className="nav-link text-white" onClick={showComingSoon}>
                        <Link className="nav-link text-white" to={{pathname: '/blog'}}>
                        <img src="/images/menubar-navbar-desktop/karma-icon.png" alt="karma"
                                    className={Styles.navbarImage}/> Karma
                        </Link> */}
                            </li>
                    <li className={Styles.navbarItem} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    {/* <Link className="nav-link text-white" onClick={showComingSoon}> */}
                        <Link className={Styles.navbarLink} to={{pathname: '/blog'}}>
                            <img src="/images/menubar-navbar-desktop/blog-icon.png" alt="blog"
                                    className={Styles.navbarImage}/> Blog
                        </Link>
                    </li>
                    <li className="nav-item haffofheroes">
                        <Link className="nav-link text-white" to={{pathname: '/'}}>
                            <img src="/images/menubar-navbar-desktop/music-icon.png" alt="music"
                                    className="img-fluid menu-img" style={{ width: '2vw' }}/> {curAudioStateName.length <= 35 ? curAudioStateName : curAudioStateName.substring(0, 32).concat('...')}
                        </Link>
                    </li>
                    <li className="nav-item ply-btn" style={{backgroundSize:"100% 100%", marginTop: 10, height: 50 }}>
                        <Link className="nav-link text-white" to={{pathname: '/'}}>
                            {!isPlay && (
                                <img src="/images/menubar-navbar-desktop/play-icon.png" style={{marginTop:"-0.7rem"}} alt="play"
                                        onClick={onMusicPlay} className="img-fluid play-img"/>
                            )}
                            {isPlay && (
                                <img src="/images/menubar-navbar-desktop/pause-icon.png" style={{marginTop:"-0.7rem"}} alt="pause"
                                        onClick={onMusicPause} className="img-fluid pause-img"/>
                            )}
                            
                        </Link>
                    </li>
                </ul>
            </nav>

            <audio ref={audioRefHomePage} className="d-none"
                                       src="/images/background-and-sounds/home-page/homepage.mp3"/>
            <audio ref={audioRefDiscovery} className="d-none"
                    src="/images/background-and-sounds/discovery-page/Hall_of_Heroes.mp3"/>
            <audio ref={audioRefPetCreation} className="d-none"
                    src="/images/background-and-sounds/pet-and-owner-creation/Beyond_The_Rainbow_Bridge_MP3.mp3"/>
            <audio ref={audioRefForestCottage} className="d-none"
                    src="/images/background-and-sounds/forest-cottage/forest-sprites.mp3"/>
            <audio ref={audioRefEvent} className="d-none" 
                    src="/images/background-and-sounds/event/Bonfire_Whispers.mp3" />

            <nav className="navbar navbar-expand-lg navbar-light bg-light navmobile" style={{zIndex:"10001", position: "fixed", width:"100%"}}>
                <ul className="navbar-nav mr-auto text-white w-100 float-left">
                    <li className="nav-item timing-area">
                        <Link className="nav-link text-white" to={{pathname: '/'}} onClick={() => setShowNavbar(false)}>
                            <img src={`/images/menubar-navbar-desktop/${timeIcon}`} className="img-fluid mornigicon"
                                 alt="img"/>
                            <span className="sr-only-top">{dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                            })}
                                <span className="sr-only-btm">{dateState.toLocaleDateString('en-GB', {
                                    weekday: 'long',
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                })}</span>
                            </span>
                        </Link>
                    </li>
                    <li className="logo-mobile">
                        <Link className="nav-link text-white" to={{pathname: '/'}} onClick={() => setShowNavbar(false)}>
                            <img src="/images/menubar-navbar-desktop/logo.png" className="img-fluid logo-img" alt="img"/>
                        </Link>
                    </li>
                    <li className="nav-item haffofheroes">
                        <Link className="nav-link text-white" to={{pathname: '/'}} onClick={() => setShowNavbar(false)}>
                            <img src="/images/menubar-navbar-desktop/music-icon.png" className="img-fluid menu-img"
                                    alt="img"/> {curAudioStateName.length <= 35 ? curAudioStateName : curAudioStateName.substring(0, 32).concat('...')}
                        </Link>
                    </li>
                    <li className="nav-item ply-btn">
                    <Link className="nav-link text-white">
                            {!isPlay && (
                                <img src="/images/menubar-navbar-desktop/play-icon.png" className="img-fluid play-img"
                                        onClick={onMusicPlay} alt="play"/>
                            )}
                            {isPlay && (
                                <img src="/images/menubar-navbar-desktop/pause-icon.png"
                                        onClick={onMusicPause} className="img-fluid pause-img" alt="pause"/>
                            )}
                        </Link>
                    </li>
                    </ul>
                <button className="navbar-toggler" type="button" onClick={() => setShowNavbar(!showNavbar)}>
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>
            {showNavbar &&<div className={Styles.backdrop} onClick={() => setShowNavbar(false)}></div>}
            {showNavbar && <ul className={Styles.navbarCollapseMobile}>
                <li className={Styles.navbarItemMobile} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    <Link className={Styles.navbarLinkMobile}  to={{pathname: '/event'}} onClick={() => setShowNavbar(false)} >
                        <img src="/images/menubar-navbar-desktop/profile-icon.png" className="img-fluid menu-img"
                                alt="img"/> Chat Room
                    </Link>
                </li>
                <li className={Styles.navbarItemMobile} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    <Link className={Styles.navbarLinkMobile} to={{pathname: '/discover'}} onClick={() => setShowNavbar(false)}>
                        <img src="/images/menubar-navbar-desktop/discover-icon.png" className="img-fluid menu-img"
                                alt="img"/> Discover
                    </Link>
                </li>
                <li className={Styles.navbarItemMobile} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    <Link className={Styles.navbarLinkMobile} to={{pathname: '/profile'}} onClick={() => setShowNavbar(false)}>
                    {/* <Link className={Styles.navbarLinkMobile} to={{pathname: '/pets'}}> */}
                        <img src="/images/menubar-navbar-desktop/pet-icon.png" alt="pet"
                                className="img-fluid menu-img"/> My Pets
                    </Link>
                </li>
                <li className={Styles.navbarItemMobile} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    <Link className={Styles.navbarLinkMobile} to={{pathname: '/shop'}} onClick={() => setShowNavbar(false)}>
                    {/* <Link className={Styles.navbarLinkMobile}  onClick={showComingSoon}> */}
                        <img src="/images/menubar-navbar-desktop/shop-icon.png" className="img-fluid menu-img"
                            alt="img"/> Shop
                    </Link>
                </li>
                <li className={Styles.navbarItemMobile} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    <Link className={Styles.navbarLinkMobile}  to={{pathname: '/event'}} onClick={() => setShowNavbar(false)}>
                        <img src="/images/menubar-navbar-desktop/karma-icon.png" className="img-fluid menu-img"
                                alt="img"/> Karma
                    </Link>
                </li>
                <li className={Styles.navbarItemMobile} style={{ backgroundImage: 'url(/images/menubar-navbar-desktop/rectangle-background.png)' }}>
                    <Link className={Styles.navbarLinkMobile} to={{pathname: '/blog'}} onClick={() => setShowNavbar(false)}>
                    {/* <Link className={Styles.navbarLinkMobile}  onClick={showComingSoon}> */}
                        <img src="/images/menubar-navbar-desktop/blog-icon.png" className="img-fluid menu-img"
                                alt="img"/>
                        Blog
                    </Link>
                </li>
            </ul>}
        </>
    );
}

export default Nav;
