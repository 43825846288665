import axiosInstance from "./AxiosInstance";

export function ownerPetCreate(data) {
    let config = {
        'content-type': 'multipart/form-data'
    };
    return axiosInstance.post(`admin/owner/pet-create`, data, config);
}

export function ownerPetCreateOld(data) {
    let config = {
        'content-type': 'multipart/form-data'
    };
    return axiosInstance.post(`pet/create`, data, config);
}

export function petCertificateGenerate(data) {
    let config = {
        'content-type': 'multipart/form-data'
    };
    return axiosInstance.post(`pet/deathcert`, data, config);
}

export function createSubscription(data) {
    return axiosInstance.post(`admin/owner/subscription`, data);
}

export function createPaypalSubscription(data) {
    return axiosInstance.post(`admin/owner/paypal/subscription`, data);
}

export function myPetList() {
    return axiosInstance.get(`admin/owner/pet-list`);
}

export function getPetDetail(data) {
    return axiosInstance.post(`admin/owner/pet-detail`, data);
}

export function getGuestBook(data) {
    return axiosInstance.post(`admin/guest-book`, data);
}

export function createComment(data) {
    return axiosInstance.post(`admin/guest-book-comment`, data);
}

export function createCommentGuest(data) {
    return axiosInstance.post(`admin/guest-book-comment-guest`, data, {headers: {'authorization': process.env.REACT_APP_SERVER_AUTHORIZATION}});
}

export function checkSubscription() {
    return axiosInstance.get(`admin/owner/get-subscription-status`);
}

export function getPetEvent(data) {
    return axiosInstance.get(`pet/event`);
}

export function registerChatParticipant(data) {
    return axiosInstance.post(`admin/event/register`, data, {headers: {'authorization': process.env.REACT_APP_SERVER_AUTHORIZATION}});
}