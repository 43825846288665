import {socialRegister, logPartnerView, login, logout, register, saveTokenInLocalStorage, savePartnersCodeInLocalStorage, getSubscriptionStatus, saveSubscriptionInLocalStorage} from "../../services/AuthService";

export const REGISTER_CONFIRMED_ACTION = '[register action] confirmed register';
export const REGISTER_FAILED_ACTION = '[register action] failed register';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const SAVE_PARTNER_CODE_ACTION = 'SAVE_PARTNER_CODE_ACTION';
export const GET_SUBSCRIPTION_DETAILS_ACTION = 'GET_SUBSCRIPTION_DETAILS_ACTION';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

export function registerAction(data, history, redirect, callback) {
    return (dispatch) => { //culprit for refresh
        register(data).then(response => {
            saveTokenInLocalStorage(response.data.data);
            dispatch(registerConfirmedAction(response.data.data));
            if(redirect){
                history.push('/profile');
            } else {
                callback("");
            }
        }).catch(err => {
            callback("Email already exist");
            dispatch(registerFailedAction(err.response.data.message));
        });
    };
}

export function socialRegisterAction(obj, history, redirect, callback) {
    return (dispatch) => {
        socialRegister(obj).then(response => {
            saveTokenInLocalStorage(response.data.data);
            dispatch(registerConfirmedAction(response.data.data));
            if(redirect){
                history.push('/profile');
            } else {
                callback("");
            }
        }).catch(err => {
            callback(err.response.data.message);
            dispatch(registerFailedAction(err.response.data.message));
        });
    };
}

export function savePartnerCode(code) {
    return (dispatch) => {
        logPartnerView(code);
        savePartnersCodeInLocalStorage(code);
        dispatch(savePartnerCodeAction(code));
    };
}

export function getSubscriptionStatusAction(callback) {
    return (dispatch) => {
        getSubscriptionStatus().then(response => {
            saveSubscriptionInLocalStorage(response.data.data);
            dispatch(subscriptionConfirmedAction(response.data.data));
            callback(response.data.data);
        }).catch(err => {
            alert(err);
        })
            
    };
}

export function loginAction(email, password, history, redirect, callback) {
    return (dispatch) => {
        login(email, password)
            .then(response => {
                saveTokenInLocalStorage(response.data.data);
                dispatch(loginConfirmedAction(response.data.data));
                if(redirect){
                    let currentUrl = localStorage.getItem("currentUrl");
                    if (currentUrl) {
                        localStorage.removeItem("currentUrl");
                        return history.push(currentUrl);
                    }
                    history.push('/profile');
                } else {
                    callback("");
                }
            })
            .catch(err => {
                callback("Your username and password is incorrect and a corresponding account cannot be found. Please check your credentials and continue.");
                dispatch(loginFailedAction(err.response.data.message));
            });
    };
}

export function logoutAction(history) {
    return (dispatch) => {
        logout()
            .then(response => {
                localStorage.removeItem('paraOwner');
                localStorage.removeItem('ownerSubscription');
                dispatch(logoutConfirmedAction());
                history.push('/login');
            })
            .catch(err => {
                //dispatch(loginFailedAction(err.response.data.message));
            });
    };
}

export function savePartnerCodeAction(partnerCode) {
    return {
        type: SAVE_PARTNER_CODE_ACTION,
        payload: partnerCode,
    };
}

export function subscriptionConfirmedAction(subscriptionDetails) {
    return {
        type: GET_SUBSCRIPTION_DETAILS_ACTION,
        payload: subscriptionDetails,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function loginFailedAction(message) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: message,
    };
}

export function registerConfirmedAction(payload) {
    return {
        type: REGISTER_CONFIRMED_ACTION,
        payload
    };
}

export function registerFailedAction(message) {
    return {
        type: REGISTER_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function logoutConfirmedAction() {
    return {
        type: LOGOUT_ACTION,
    };
}

export function resetErrorMessage(){
    return{
        type: RESET_ERROR_MESSAGE,
    }
}
