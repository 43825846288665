export const isAuthenticated = (state) => {
    try{
        if (state.auth.auth.token) return true;
    } catch (ex){
        return false;
    }
    return false;
};

export const isSubscribed = (state) => {
    try{
        if (state.subscription.status == "active") return true;
    } catch (ex){
        return false;
    }
    return false;
};